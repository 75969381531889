.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App_pageTitle {
  background: #1e9bd3;
  color: white;
  text-align: center;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  border-bottom: 1px solid #eee !important;
  padding: 12px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.App-header {
  background-color: transparent;
  padding: 12px;
  margin-top: 56px;
}

.App-content {
  border-radius: 6px;
  /* padding: 22px; */
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(73, 62, 136);
  min-height: 520px;
  min-width: 290px;
  width: 100%;
  background: white;
}

@media screen and (min-width: 600px) {
  .App-header {
    margin-left: 240px;
    margin-top: 64px;
    min-height: calc(100vh - 68px);
    padding: 20px 120px;
  }
}

.App .MuiCircularProgress-colorPrimary {
  margin-top: 100px;
  margin-left: calc(50% - 20px);
}

.MuiButton-root {
  text-transform: none !important;
}

.App-content .MuiCheckbox-colorSecondary.Mui-checked {
  color: #17e636;
}

.App-content .makeStyles-root-50 .MuiDataGrid-columnsContainer,
.App-content .makeStyles-root-50 .MuiTypography-colorInherit,
.App-content .makeStyles-root-50 .MuiSelect-select.MuiSelect-select {
  background: white;
  color: black;
}

.MuiBox-root {
  width: 100%;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-wrapper {
  display: inline-flex;
}

.column1 {
  float: left;
  width: 50%;
  text-align: center;
}

.column2 {
  float: left;
  width: 100%;
  text-align: center;
}

.footnote {
  float: left;
  text-align: left;
  font-size: small;
  color: #808080;
  margin-left: 8pt;
}

.floating-button {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  font-size: 30px; 
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.custom-select {
  position: relative;
  font-family: Arial;
  margin-top: 20px;
}

.custom-select select {
  border: 1px solid #ccc;          
  border-radius: 5px;           
  padding: 5px;                   
  font-size: 16px;               
  width: 200px;                   
  background-color: #fff;         
  outline: none;                 
  cursor: pointer; 
  margin-left: 5px;
}

.custom-select label select option,
.custom-select label select-items {
  background-color: #fff;      
  color: #333;                    
  padding: 5px;   
  border-radius: 5px;   
}             

.custom-select label select option:hover {
  background-color: #1e9bd3 !important;             
}

.custom-select select:hover {
  border: 1px solid #666;         
}

.custom-select label {
  font-size: medium;
  margin-left: 10px;
  margin-right: 10px;
}

.datepicker input{
  margin-left: 20px;
}

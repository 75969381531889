.timerecord-overview {
  &__row {
    display: none;
  }
  @media screen and (max-width: 600px) {
    &__comment {
      display: none;
    }
    &__row {
      display: inline-block;
      margin-right: 4px;
      font-weight: bold;
    }

    .MuiPaper-root {
      margin-top: 0;
    }
    thead {
      display: none;
    }

    tbody tr {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      white-space: pre-line;

      th {
        width: 100%;
        text-align: left;
      }
    }
  }
}

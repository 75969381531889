.text-link {
  color: inherit;
  text-decoration: none;
}

.textDecorationLink {
  .MuiListItem-button:hover {
    background-color: transparent;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: transparent;
    box-shadow: -5px 0px 0px 0px #222;
  }
}
